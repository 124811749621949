<template>
  <div class="home" :class="pageType">
    <div class="home-container">
      <BespokeInput
        v-if="!getIfLoadingUsersAreas && getUsersAreas.length"
        class="home-header__search"
        :icon="require('@/assets/search-icons/search.svg')"
        v-model="search"
      >
        <span class="text-italic"><b>Search</b> <span class="text-mid-grey">Spaces</span></span>
      </BespokeInput>

      <div class="home-pending-invites" v-if="pendingInvites.length">
        <div class="home-header">
          <h1 class="home-header__text">
            My pending invitations <span class="text-mid-grey text-weight-normal">({{pendingInvites.length}})</span>
          </h1>
        </div>
        <slide-in-transition-group
          class="home-spaces"
          :total="pendingInvites.length"
          :blinkOut="true"
          :duration="1000"
        >
        <SpaceListing
            v-for="(space,index) in pendingInvites"
            :key="space.areaId"
            :space="space"
            :style="{'--i': index}"
            variant="invite"
        />
        </slide-in-transition-group>
      </div>

      <div class="home-header">
        <h1 class="home-header__text" :class="{'faded': spacesList.length === 0}">
          Your Spaces collection <span class="text-mid-grey text-weight-normal">({{spacesList.length}})</span>
        </h1>
      </div>


      <slide-in-transition-group
        class="home-spaces"
        :total="spacesList.length + 2 + skeletonSpacesNo"
        :blinkOut="true"
        :duration="1000"
      >
        <SpaceListing
          v-for="(space,index) in spacesList"
          :key="space.id"
          :space="space"
          :style="{'--i': index}"
        />

        <div v-if="!getIfLoadingUsersAreas && !spacesList.length && !search"
          class="home-spaces-info"
          key="info"
        >
          <h2>Welcome to SPACEIN. <br>This is where your Spaces live.</h2>
          <p>
            A Space is a visualised digital environment where you can connect with your peers. You can create multiple Spaces or join a Space created by other members. Come back, and connect anytime, anywhere.
          </p>
          <p class="grey">
            You currently don’t have a Space assigned to your account. Why not create your own?
          </p>
          <p>
          All Spaces are FREE!*
          </p>
          <span class="disclaimer">
            *For up to 25 users per Space
          </span>
        </div>

        <div
          v-if="!getIfLoadingUsersAreas"
          class="home-spaces-create"
          key="create-space"
          @click="goToCreateArea"
          :style="{'--i': spacesList.length}"
        >
          <img class="home-spaces-create__icon" src="@/assets/global-icons/add-gradient-circle.svg" alt="">
          <div>Create new Space</div>
        </div>

        <div
          v-if="!getIfLoadingUsersAreas && !spacesList.length && search"
          class="home-spaces-none"
          :style="{ '--i': spacesList.length + 1}"
          key="no-space"
          @click="goToCreateArea"
        >
          <img src="@/assets/images/not-found.svg"/>

          <h2>No Spaces found.</h2>
          <p>Why not create <b>{{ search }}</b> Space?</p>
        </div>

        <template v-if="getIfLoadingUsersAreas">
          <Skeleton
            v-for="(skeleton,index) in skeletonSpacesNo"
            :style="{
              '--i': spacesList.length+index+2
            }"
            :key="skeleton"
            size="310px 460px" rounded gradient noverflow
          >
            <Skeleton size="100% 180px" shimmer/>
            <Skeleton size="120px" circle absolute offsets="100px 0 0 25px"/>
            <Skeleton size="20px 40px" rounded-full absolute offsets="10px 10px 0"/>
            <div style="padding: 57px 25px 0">
              <Skeleton size="70% 25px" margin="0 0 5px" shimmer rounded-sm/>
              <Skeleton size="50% 20px" margin="0 0 27px" shimmer rounded-sm/>
              <Skeleton size="60% 15px" margin="0 0 27px" shimmer rounded-sm/>
              <Skeleton size="100% 12px" margin="0 0 8px" shimmer rounded-sm/>
              <Skeleton size="100% 12px" margin="0 0 8px" shimmer rounded-sm/>
              <Skeleton size="100% 12px" margin="0 0 8px" shimmer rounded-sm/>
            </div>
          </Skeleton>
        </template>
      </slide-in-transition-group>
    </div>
  </div>
</template>

<script>
import SlideInTransitionGroup from '@/components/transitions/SlideInTransitionGroup.vue'
import SpaceListing from '@/components/home_page/SpaceListing.vue';
import Skeleton from '@/components/global/loading/Skeleton.vue';
import BespokeInput from '../components/global/inputs/BespokeInput.vue';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Home',
  props: {
    pageType: String
  },
  components: {
    SlideInTransitionGroup,
    BespokeInput,
    SpaceListing,
    Skeleton
  },
  data: () => ({
    search: '',
    skeletonSpacesNo: 3,
  }),
  computed: {
    ...mapGetters(['getUsersAreas', 'getIfLoadingUsersAreas', 'getPendingInviteAreas']),
    spacesList: vm => vm.getUsersAreas.filter(
      x => x.title.toLowerCase().includes( vm.search.toLowerCase() )
    ),
    pendingInvites: vm => vm.getPendingInviteAreas.filter(
      x => x.title.toLowerCase().includes( vm.search.toLowerCase() )
    )
  },
  methods: {
    ...mapActions('routing', ['goToCreateArea']),
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/scss/pages/home.scss';
</style>
