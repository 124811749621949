<template>
	<div class="space-listing"
		:class="['space-listing--' + this.variant]"
		@click="switchAreaHandler(space.id)">
		<div
			class="space-listing-cover"
			:style="{
				'--background-color': space.primaryColour
			}"
		>
			<GenericMenu v-if="isNormalVariant" class="space-listing-cover__menu" :items="[{
				icon: 'delete',
				text: 'Remove me from Space',
				click: () => handleLeaveSpace(space.id)
			}]"/>
			<img v-if="coverImage" :src="coverImage" loading="lazy" class="space-listing-cover__image" alt="Space cover image"/>
			<SpaceLogo class="space-listing-logo" :logo="logo" :title="title" diameter="120px"/>
		</div>

		<div class="space-listing-text">
			<h2 class="space-listing-text__title">{{ space.title }}</h2>
			<div class="space-listing-text__company">{{ space.companyName }}</div>
			<div v-if="space.info.heading" class="space-listing-text__info-title">{{space.info.heading}}</div>
			<div v-if="space.info.description" class="space-listing-text__info-text">{{space.info.description}}</div>
		</div>

		<div v-if="isNormalVariant" class="space-listing-online">
			<img class="space-listing-online__icon" src="@/assets/global-icons/user-circle.svg"/>
			<div class="space-listing-online__number">{{space.online}}</div>
			<div v-if="false" class="space-listing-online__live">LIVE</div>
			<img class="space-listing-online__arrow" src="@/assets/global-icons/arrow-right-white.svg"/>
		</div>
		<div v-if="isInviteVariant" class="space-listing-invite-buttons">
			<btn :disabled="processingInvite" @onClick="dismissInvite" colour="ghost">
				<template #text>Dismiss</template>
			</btn>

			<btn :disabled="processingInvite" @onClick="acceptInvite" colour="green">
				<template #text>Accept invitation</template>
			</btn>
		</div>
	</div>
</template>

<script>
import GenericMenu from '@/components/menu/GenericMenu.vue';
import SpaceLogo from '@/components/atoms/SpaceLogo.vue';
import { processMediaURL } from '@/functions/utility.js';

import { mapActions } from 'vuex';

export default {
	name: 'SpaceListing',
	components: { GenericMenu, SpaceLogo },
	props: {
		space: Object,
		variant: {
			type: String,
			default: "normal" // normal, invite
		},
	},
	data() {
		return {
			processingInvite: false
		}
	},
	computed: {
		logo: vm => vm.space.logo,
		title: vm => vm.space.title,
		coverImage: vm => processMediaURL(vm.space.info.coverImage),
		isNormalVariant: vm => vm.variant == 'normal',
		isInviteVariant: vm => vm.variant == 'invite',
	},
	methods: {
		...mapActions(['switchAreaHandler', 'handleLeaveSpace', 'handleAcceptSpaceInvite', 'handleRejectSpaceInvite', 'enterSwitchAreaPageHandler']),
		async acceptInvite() {
			this.processingInvite = true

			await this.handleAcceptSpaceInvite([this.space.inviteToken, this.space])
			await this.enterSwitchAreaPageHandler()
		},

		async dismissInvite() {
			this.processingInvite = true

			await this.handleRejectSpaceInvite([this.space.inviteToken, this.space])
			await this.enterSwitchAreaPageHandler()
		}
	}
}
</script>

<style lang="scss" scoped>
.space-listing {
	width: 308px;
	flex-shrink: 0;
	border-radius: 17px;
	box-shadow: 0px 0px 8px #00000080;
	background: $grey-4;
	overflow: hidden;
	cursor: pointer;
	line-height: 1;
	display: flex;
	flex-flow: column;
	height: 451px;

	&--invite {
		cursor: unset;
	}
	&--space-lobby {
	}

	@include mobile-only {
		width: 100%;
		max-width: 500px;
	}

	&-cover {
		height: 180px;
		position: relative;
		background-size: cover;
		background-position: center;
		background-color: var(--background-color);

		&__menu {
			position: absolute;
			right: 10px;
			top: 10px;
			z-index: 5;
		}

		&__image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
			object-fit: cover;
			object-position: top left;
		}
	}

	&-logo {
		position: absolute;
		bottom: -35px;
		left: 20px;
	}

	&-text {
		padding: 50px 25px 0;
		color: #c7c7c7;
		font-size: 14px;
		overflow: hidden;
		flex-grow: 1;

		> * {
			margin-bottom: 20px;
		}
		// allows the distance between the online bit and the text to be determined by the online bit
		> *:last-child {
			margin-bottom: 0;
		}

		&__title, &__company {
			color: white;
		}

		&__title {
			font-size: 24px;
			margin-bottom: 10px;
		}

		&__info-title {
			font-weight: bold;
		}
		&__info-text {
			line-height: 1.35;
			max-height: 54px;
			overflow: hidden;
			// unfortunately doesn't work for vertical overflow - maybe someday, though
			text-overflow: ellipsis;
		}
	}

	&-invite-buttons {
		display: flex;
		justify-content: space-between;
		gap: 10px;
		padding: 20px 25px 20px;

		& .btn {
			flex-grow: 1;
		}

		@include mobile-only {
			flex-direction: column;
		}
	}

	&-online {
		display: flex;
		padding: 25px 20px 20px 25px;
		align-items: center;
		color: white;
		font-size: 16px;

		&__number {
			flex-grow: 1;
			margin-left: 6px;
		}

		&__live {
			background: $suspended-color;
			border-radius: 15px;
			line-height: 0.9;
			padding: 6px 11px;
			margin-right: 25px;
		}
	}
}
</style>
